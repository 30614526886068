<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell callout success">
        <h3><img width="35px" src="../assets/icons/plant-a-tree.png"/>&nbsp;&nbsp;&nbsp;<b>So</b>ftware für <b>S</b>oziale <b>E</b>inrichtungen</h3>
      </div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">
      <div class= "cell">
        <div v-if="isAuthenticated && !isSage && organization && dataAvailable">
          <div v-if="facilitiesNotExists" class="grid-x grid-padding-x grid-padding-y">
            <NoFacilityCard alternative-text="Die <span style='color: cornflowerblue'><b>Übersicht</b></span> und der <span style='color: cornflowerblue'><b>Belegungsplan</b></span> können ohne Einrichtungen nicht erstellt werden."></NoFacilityCard>
          </div>
          <md-tabs v-else md-elevation="1" style="height: 100%;" md-active-tab="tab-overview" @md-changed="onSelectTab">
            <md-tab v-if="organization.baseModuleActive" id="tab-overview" md-label="Übersicht">
              <md-card style="min-height: 735px;">
                <md-card-header>
                  <div class="grid-x grid-padding-x">
                    <div class="cell large-9">
                      <FacilityOverviewView :overviews="overviews" :statistic="statistic" :facility="selectedFacility"
                                            :year="selectedYear" :sending="sending" :simulationActive="simulationActive"
                                            @previousYear="onPreviousYear" @nextYear="onNextYear"></FacilityOverviewView>
                    </div>
                    <div class="cell large-3">
                      <div class="grid-x grid-padding-x">
                        <div class="cell">
                          <md-field>
                            <label>Einrichtung</label>
                            <md-select v-model="selectedFacilityReferenceNumber"
                                       :disabled="sending > 0 || allowedFacilities.length <= 1 || simulationActive"
                                       @md-selected="onSelectFacility">
                              <md-option v-for="facility of allowedFacilities" :value="facility.referenceNumber"
                                         :key="facility.id">
                                {{ facility.longName }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell" style="padding: 0;">
                          <SimulationCard ref="overviewSimulationCardRef"
                                          :selectedFacilityReferenceNumber="selectedFacilityReferenceNumber"
                                          :selectedYear="selectedYear"
                                          :simActive="simulationActive"
                                          @toggleSimulation="onToggleSimulation"
                                          @simulateStatistic="onSimulateStatistic"
                                          @updateSimulationData="onUpdateSimulationData"></SimulationCard>
                        </div>
                      </div>
                      <md-progress-bar v-if="sending > 0" md-mode="indeterminate"></md-progress-bar>
                    </div>
                  </div>
                </md-card-header>
              </md-card>
            </md-tab>
            <md-tab v-if="organization.baseModuleActive" id="tab-capacity" md-label="Analyse">
              <md-card>
                <md-card-header>
                  <div class="grid-x grid-padding-x">
                    <div class="cell large-9">
                      <StatisticView :statistic="statistic" :sending="sending" :year="selectedYear" :facility="selectedFacility"
                                     :simulationActive="simulationActive"
                                     @previousYear="onPreviousYear" @nextYear="onNextYear">
                      </StatisticView>
                    </div>
                    <div class="cell large-3">
                      <div class="grid-x grid-padding-x">
                        <div class="cell large-12 medium-6">
                          <md-field>
                            <label>Einrichtung</label>
                            <md-select v-model="selectedFacilityReferenceNumber"
                                       :disabled="sending > 0 || allowedFacilities.length <= 1 || simulationActive"
                                       @md-selected="onSelectFacility">
                              <md-option v-for="facility of allowedFacilities" :value="facility.referenceNumber"
                                         :key="facility.id">
                                {{ facility.longName }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell large-12 medium-6 small-6">
                          <md-field>
                            <label>Jahr</label>
                            <md-select v-model="selectedYear" :disabled="sending > 0 || simulationActive" @md-selected="onSelectFacility">
                              <md-option v-for="year of assignmentPlanYears" :value="year" :key="year">
                                {{year}}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell large-12 medium-6 small-6">
                          <md-switch class="md-primary" v-model="kigaYear" @change="onToggleKigaYear()" :disabled="simulationActive">
                            <p>Kindergarten-Jahr</p>
                            <md-tooltip>Jahresüberblick von September bis August</md-tooltip>
                          </md-switch>
                        </div>
                        <div class="cell" style="padding: 0;">
                          <SimulationCard ref="analysisSimulationCardRef"
                                          :selectedFacilityReferenceNumber="selectedFacilityReferenceNumber"
                                          :selectedYear="selectedYear"
                                          :simActive="simulationActive"
                                          @toggleSimulation="onToggleSimulation"
                                          @simulateStatistic="onSimulateStatistic"
                                          @updateSimulationData="onUpdateSimulationData"></SimulationCard>
                        </div>
                      </div>
                      <md-progress-bar v-if="sending > 0" md-mode="indeterminate"></md-progress-bar>
                    </div>
                  </div>
                </md-card-header>
              </md-card>
            </md-tab>
            <md-tab v-if="organization.baseModuleActive" id="tab-plan" md-label="Belegungsplan">
              <md-card>
                <md-card-header>
                  <div v-if="!simulationActive" class="grid-x grid-padding-x">
                    <div class="cell large-8">
                      <AssignmentPlanView :assignmentPlan="assignmentPlan" :facility="selectedFacility" :sending="sending"
                                          :year="selectedYear" :month="selectedMonth" :selectedDivisions="selectedDivisions"
                                          :selectedDay="selectedDay" :selectedInterval="selectedInterval"
                                          @updateChildren="onUpdateChildren"
                                          @previousMonth="onPreviousMonth" @nextMonth="onNextMonth">
                      </AssignmentPlanView>
                    </div>
                    <div class="cell large-4">
                      <div class="grid-x grid-padding-x">
                        <div class="cell large-12 medium-6">
                          <md-field>
                            <label>Einrichtung</label>
                            <md-select v-model="selectedFacilityReferenceNumber"
                                       :disabled="sending > 0 || allowedFacilities.length <= 1"
                                       @md-selected="onSelectFacility">
                              <md-option v-for="facility of allowedFacilities" :value="facility.referenceNumber"
                                         :key="facility.id">
                                {{ facility.longName }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell large-12 medium-6">
                          <md-field>
                            <label>Bereiche</label>
                            <md-select v-model="selectedDivisions" :disabled="sending > 0" @md-selected="onSelectDivision"
                                       multiple>
                              <md-option v-for="division of labels.divisionTypes" :value="division"
                                         :key="division" v-if="division !== 'UNDEFINED'">
                                {{labels.divisiontype[division]}}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell large-6 medium-6 small-6">
                          <md-field>
                            <label>Jahr</label>
                            <md-select v-model="selectedYear" :disabled="sending > 0" @md-selected="onSelectFacility">
                              <md-option v-for="year of assignmentPlanYears" :value="year" :key="year">
                                {{year}}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell large-6 medium-6 small-6">
                          <md-field>
                            <label>Monat</label>
                            <md-select v-model="selectedMonth" :disabled="sending > 0" @md-selected="onSelectFacility">
                              <md-option v-for="(month, index) of $material.locale.months" :value="(index + 1)" :key="index">
                                {{month}}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>

                        <div class="cell" style=" height: 1rem;">
                          <md-progress-bar v-if="sending > 0" md-mode="indeterminate"></md-progress-bar>
                        </div>

                        <div class="cell">

                          <md-table v-model="searchedChildren" md-sort="fullName" md-sort-order="desc"
                                    md-card md-fixed-header style="height: 399px">
                            <md-table-toolbar :style="toolBarStyle">
                              <div class="md-toolbar-section-start grid-x grid-padding-x">
                                <div class="cell large-4 hide-for-medium-only hide-for-small-only">
                                  <p class="md-title ai-number">{{searchedChildren.length}} Kind<span v-if="searchedChildren.length !== 1">er</span></p>
                                  <p v-if="selectedInterval">{{selectedDay}} {{selectedInterval}} Uhr</p>
                                  <p v-else><i>Kein Auswahl</i></p>
                                </div>
                                <div class="cell large-8 medium-12 small-12">
                                  <md-field md-clearable class="md-toolbar-section-end">
                                    <md-input placeholder="Suche Name ..." v-model="searchChild"
                                              @input="searchOnChildTable"/>
                                  </md-field>
                                </div>
                              </div>
                            </md-table-toolbar>

                            <md-table-empty-state v-if="searchChild"
                                                  md-label="Kein Kind gefunden"
                                                  :md-description="`Kein Kind mit dem Suchbegriff '${searchChild}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)">
                              <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Gen" md-sort-by="sex">
                                <span v-if="item.sex === 'MALE'" :class="item.sex">&#9794;</span>
                                <span v-else-if="item.sex === 'FEMALE'" :class="item.sex">&#9792;</span>
                                <span v-else-if="item.sex === 'DIVERSE'" :class="item.sex">&#9954;</span>
                              </md-table-cell>
                              <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Name"
                                             md-sort-by="fullName">
                                <ImageView :imageId="item.imageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;&nbsp;
                                <router-link :to="openChildLink(item.id)"><span>{{item.fullName}}</span>
                                  <md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für <b>{{item.fullName}}</b> ({{labels.divisiontype[item.divisiontype]}})</md-tooltip>
                                </router-link>
                              </md-table-cell>
                              <md-table-cell md-label="Alter" md-sort-by="ageTextual">{{ item.ageTextual }}
                              </md-table-cell>
                            </md-table-row>
                          </md-table>

                        </div>

                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <span style="font-size: large;"><b class="flash-orange-red"><i>Simulation aktiv</i></b>&nbsp;&nbsp;&nbsp;
                      <i style="font-size: medium;">Während der Simulation kann der Belegungsplan nicht angezeigt werden.</i>
                    </span>
                  </div>
                </md-card-header>
              </md-card>
            </md-tab>
            <md-tab id="tab-info" md-label="Informationen">
              <SoSEInformationView></SoSEInformationView>
            </md-tab>
            <md-tab v-if="(isAdmin || isSage) && organization.baseModuleActive" id="tab-system" md-label="System">
              <SystemView></SystemView>
            </md-tab>
          </md-tabs>
        </div>
        <div v-if="isAuthenticated && isSage">
          <md-tabs md-elevation="1" style="height: 100%;" md-active-tab="tab-Info">
            <md-tab id="tab-info" md-label="Informationen">
              <SoSEInformationView></SoSEInformationView>
            </md-tab>
            <md-tab id="tab-system" md-label="System">
              <SystemView></SystemView>
            </md-tab>
          </md-tabs>
        </div>
        <SoSEInformationView v-if="!isAuthenticated"></SoSEInformationView>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import FacilityService from '../services/FacilityService';
  import InfoService from '../services/InfoService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import ImageView from '../components/views/ImageView';
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import Spinner from 'vue-simple-spinner';
  import {Md5} from 'ts-md5/dist/md5';
  import SoSEInformationView from '../components/views/SoSEInformationView';
  import SystemView from "../components/views/system/SystemView";

  const StatisticView = () => import(/* webpackChunkName: "group-dashboard" */ '../components/views/StatisticView');
  const FacilityOverviewView = () => import(/* webpackChunkName: "group-dashboard" */ '../components/views/FacilityOverviewView');
  const AssignmentPlanView = () => import(/* webpackChunkName: "group-dashboard" */ '../components/views/AssignmentPlanView');
  const SimulationCard = () => import(/* webpackChunkName: "group-dashboard" */ '../components/cards/SimulationCard');

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchChildByFullName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.fullName).includes(toLower(term)));
    }

    return items;
  };

  const searchByDivision = (items, terms) => {
    if (terms) {
      return items.filter(item => {
        for (let term of terms) {
          if (toLower(item.divisiontype).includes(toLower(term))) {
            return true;
          }
        }
        return false;
      });
    }

    return items;
  };

  export default {
    name: 'Home',

    components: {
      SystemView,
      SimulationCard,
      FacilityOverviewView,
      StatisticView,
      AssignmentPlanView,
      SoSEInformationView,
      ImageView,
      NoFacilityCard,
      'vue-simple-spinner': Spinner,
    },

    mounted() {
      this.restoreUserSettings();
      setTimeout(() => {
        if (this.isAuthenticated) {
          this.reloadData()
        }
      }, 250);

      HttpErrorHandler.maintainDarkMode(this);
    },

    watch: {
      selectedMonth() {
        this.resetSettings();
      },
      selectedYear() {
        this.resetSettings();
      },
    },

    data() {
      return {
        sending: 0,
        kigaYear: false,

        availableFacilities: [],
        allowedFacilities: [],
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',
        selectedFacility: null,

        assignmentPlan: null,
        statistic: null,
        overviews: null,

        reloadParametersMd5Hash: null,

        assignmentPlanYears: [],
        selectedYear: Vue.moment().format("YYYY"),
        selectedMonth: Vue.moment().format("M"),
        selectedDate: Vue.moment(),

        previousSelectedFacilityReferenceNumber: null,
        previousSelectedYear: null,
        previousSelectedMonth: null,
        previousKigaYear: null,

        selectedDivisions: [],

        children: [],
        searchedChildren: [],
        searchChild: null,
        selectedDay: null,
        selectedInterval: null,
        selectedTabId: '',

        simulationActive: false,
      }
    },

    methods: {

      resetSettings() {
        this.searchedChildren = [];
        this.searchChild = null;
        this.selectedDay = null;
        this.selectedInterval = null;
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      openChildLink(id) {
        return '/Kinder/'+ id;
      },

      onSelectTab(tabId) {
        this.selectedTabId = tabId;
      },

      searchOnChildTable() {
        this.searchedChildren = searchChildByFullName(this.children, this.searchChild);
        this.searchedChildren = searchByDivision(this.searchedChildren, this.selectedDivisions);
      },

      onUpdateChildren(children, day, interval) {
        this.children = children;
        this.selectedDay = day;
        this.selectedInterval = interval;
        this.searchOnChildTable();
      },

      restoreUserSettings() {
        if (localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber')) {
          this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        }
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = null;
        }
        if (localStorage.getItem(this.user.md5 + '@selectedDivisions')) {
          this.selectedDivisions = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedDivisions'));
        }
      },

      initData() {
        if (this.assignmentPlanYears.length <= 0) {
          for (let i = 0; i < 12; i++) {
            this.assignmentPlanYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) - i + 1), 'DD-MM-YYYY').format('YYYY'));
          }
        }

        if (this.labels && this.labels.divisiontype && this.selectedDivisions && this.selectedDivisions.length <= 0) {
          this.selectedDivisions = [];
          Object.keys(this.labels.divisiontype).forEach(key => {
            this.selectedDivisions.push(key);
          });
        }
      },

      reloadData() {
        if (this.isSage) {
          return;
        }

        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (!this.isAdmin) {
              if (!this.selectedFacilityReferenceNumber && this.user.allowedFacilities.length > 0 && this.user.allowedFacilities[0]) {
                this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0];
              }
            }

            this.sending = 0;
            this.initData();
            this.buildAvailableFacilities();
            this.onSelectFacility();

            clearInterval(reloadIntervalId);
          } else {
            this.sending = 1;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = 0;
              clearInterval(reloadIntervalId);
              if (this.isAuthenticated) {
                HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
              }
            }
          }
        }, 250);
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;

        if ((!this.selectedFacilityReferenceNumber || this.selectedFacilityReferenceNumber === 'undefined') && this.availableFacilities.length > 1) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[1].referenceNumber;
          this.selectedFacilityName = this.availableFacilities[1].name;
          this.selectedFacility = this.availableFacilities[1];
        }

        this.allowedFacilities = [];
        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber !== '*') {
            this.allowedFacilities.push(facility);
          }
        }
      },

      onPreviousMonth() {
        if (this.assignmentPlanYears.length > 0 && this.selectedYear >= this.assignmentPlanYears[this.assignmentPlanYears.length - 1] && this.selectedMonth > 0 &&
          !(this.selectedYear === this.assignmentPlanYears[this.assignmentPlanYears.length - 1] && Number(this.selectedMonth) === 1)) {
          this.selectedYear = Vue.moment(this.selectedDate).subtract(1, 'months').format('YYYY');
          this.selectedMonth = Vue.moment(this.selectedDate).subtract(1, 'months').format('M');
          this.selectedDate = Vue.moment(this.selectedDate).subtract(1, 'months');
        }
      },

      onNextMonth() {
        if (this.assignmentPlanYears.length > 0 && this.selectedYear <= this.assignmentPlanYears[0] && this.selectedMonth <= 12 &&
          !(this.selectedYear === this.assignmentPlanYears[0] && Number(this.selectedMonth) === 12)) {
          this.selectedYear = Vue.moment(this.selectedDate).add(1, 'months').format('YYYY');
          this.selectedMonth = Vue.moment(this.selectedDate).add(1, 'months').format('M');
          this.selectedDate = Vue.moment(this.selectedDate).add(1, 'months');
        }
      },

      onPreviousYear() {
        if (this.assignmentPlanYears.length > 0 && this.selectedYear > this.assignmentPlanYears[this.assignmentPlanYears.length - 1]) {
          this.selectedYear = Vue.moment(this.selectedDate).subtract(1, 'years').format('YYYY');
        }
      },

      onNextYear() {
        if (this.assignmentPlanYears.length > 0 && this.selectedYear < this.assignmentPlanYears[0]) {
          this.selectedYear = Vue.moment(this.selectedDate).add(1, 'years').format('YYYY');
        }
      },

      onToggleKigaYear() {
        this.onSelectFacility();
      },

      onSelectDivision() {
        localStorage.setItem(this.user.md5 + '@selectedDivisions', JSON.stringify(this.selectedDivisions));
        this.searchOnChildTable();
      },

      onSelectFacility() {
        this.selectedDate = Vue.moment(this.selectedMonth + '-' + this.selectedYear, 'MM-YYYY');

        if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber',this.selectedFacilityReferenceNumber);

          this.selectedFacilityName = '';
          this.selectedFacility = null;
          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = facility.name;
              this.selectedFacility = facility;
            }
          }

          if (this.reloadParametersMd5Hash !== Md5.hashAsciiStr(this.selectedDate + this.selectedFacilityReferenceNumber + this.kigaYear)) {

            this.reloadParametersMd5Hash = Md5.hashAsciiStr(this.selectedDate + this.selectedFacilityReferenceNumber + this.kigaYear);

            if (this.previousSelectedYear !== this.selectedYear || this.previousSelectedMonth !== this.selectedMonth || this.previousSelectedFacilityReferenceNumber !== this.selectedFacilityReferenceNumber) {
              this.getAssignmentPlan(this.selectedFacilityReferenceNumber, this.selectedYear, this.selectedMonth);
            }

            if (this.previousSelectedYear !== this.selectedYear || this.previousKigaYear !== this.kigaYear  || this.previousSelectedFacilityReferenceNumber !== this.selectedFacilityReferenceNumber) {
              this.getKibigData(this.selectedFacilityReferenceNumber, this.selectedYear, this.kigaYear);
            }

            this.previousSelectedFacilityReferenceNumber = this.selectedFacilityReferenceNumber;
            this.previousSelectedYear = this.selectedYear;
            this.previousSelectedMonth = this.selectedMonth;
            this.previousKigaYear = this.kigaYear;

            if (!this.overviews) {
              this.getOverview();
            }

            this.resetSettings();
          }
        }
      },

      getAssignmentPlan(facilityReferenceNo, year, month) {
        if (!this.facilitiesNotExists) {
          this.sending++;
          FacilityService.getAssignmentPlan(facilityReferenceNo, year, month)
            .then(response => {
              this.sending--;
              this.assignmentPlan = response.data;
            })
            .catch(e => {
              this.sending--;
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden des Belegungsplans der Einrichtung '
                + this.selectedFacilityName + ' für das Jahr ' + year + ' und Monat ' + month);
            })
        }
      },

      getKibigData(facilityReferenceNo, year, kigaYear) {
        if (!this.facilitiesNotExists) {
          this.sending++;
          FacilityService.calcKibigData(facilityReferenceNo, year, kigaYear)
            .then(response => {
              this.sending--;
              this.statistic = response.data;
            })
            .catch(e => {
              this.sending--;
              HttpErrorHandler.handleError(e, this, 'Fehler beim Berechnen der KiBiG-Daten der Einrichtung '
                + this.selectedFacilityName + ' für das Jahr ' + year);
            })
        }
      },

      getOverview() {
        this.sending++;
        InfoService.getOverview()
          .then(response => {
            this.sending--;
            this.overviews = response.data;
          })
          .catch(e => {
            this.sending--;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Übersichtsdaten für Einrichtung '
              + this.selectedFacilityName);
          })
      },

      onToggleSimulation(active) {
        this.simulationActive = active;
        if (!this.simulationActive) {
          this.getKibigData(this.selectedFacilityReferenceNumber, this.selectedYear, this.kigaYear);
        }
      },

      onSimulateStatistic(simulatedStatistic) {
        this.statistic = simulatedStatistic;
      },

      onUpdateSimulationData() {
        this.$refs.overviewSimulationCardRef.restoreSimulationData();
        this.$refs.analysisSimulationCardRef.restoreSimulationData();
      },
    },

    computed: {

      facilitiesNotExists() {
        return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
      },

      dataAvailable() {
        return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
            this.user.allowedFacilities.length > 0 && this.labels &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      toolBarStyle() {
        return this.searchedChildren.length > 0 ? 'background: #faebd780' : '';
      },

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isSage() {
        return this.$store.getters.sage;
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      user() {
        return this.$store.getters.user;
      },

      version() {
        return this.$store.getters.version;
      },

      labels() {
        return this.$store.getters.labels
      },

      organization() {
        return this.$store.getters.organization;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },
    }
  }
</script>

<style lang="scss" scoped>

  .md-toolbar.md-theme-default.md-transparent .md-title {
    color: cornflowerblue;
    margin-left: 0;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-tab {
    padding: 16px 1px 1px;
  }

  h5 {
    font-weight: 300;
  }

  h5 b {
    font-weight: 400;
    color: cadetblue;
  }

  .centered {
    position: absolute;
    top: 85%;
    left: 50%;
    right: -40%;
    transform: translate(-35%, -50%);
  }

  h4 {
    font-weight: 300;
  }

  p {
    margin: 0;
  }

  i {
  }

  .button {
    margin: 0;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 330px;
    white-space: pre-wrap;
  }

  @keyframes flashActive {
    0% {
      font-size: 14px !important;
      opacity: 1;
    }

    50% {
      font-size: 24px !important;
      opacity: 0;
    }

    100% {
      font-size: 14px !important;
      opacity: 1;
    }
  }
</style>
