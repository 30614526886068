<template>
  <div>
    <md-card>
      <div class="grid-x">
        <div class="cell medium-4">
          <md-card-media>
            <img src="../../assets/img/kita-kunterbunt-building.jpg" alt="Träger">
          </md-card-media>
        </div>
        <div class="cell medium-8">
          <md-card-header>
            <div class="md-title">Software für Soziale Einrichtungen (SoSE)</div>
            <div class="">Ein Produkt der
              <span>
                <a href="http://www.ai42.de/" target="_blank">ai42 UG (haftungsbeschränkt)</a>
                <md-tooltip md-direction="top" style="font-size: larger">www.<b>ai42</b>.de</md-tooltip>
              </span>
            </div>
            <br>
            <div v-if="version" class="md-subhead">
              Server Version <b class="ai-standard-text">{{ version.server.version }}</b>
              &mdash; Frontend Version <b class="ai-standard-text">{{ version.frontend.version }}</b>
              &mdash; <a href="https://www.ai42.de/release-notes/sose-release-notes" target="_blank">Release
              Informationen
              <md-tooltip>Mehr Informationen zu diesem Release</md-tooltip>
            </a>
            </div>
            <br>
            <div class="md-subhead">
              Icons made by
              <span>
                <a href="http://www.freepik.com" target="_blank">Freepik</a>
                <md-tooltip md-direction="top" style="font-size: larger">www.<b>freepik</b>.com</md-tooltip>
              </span> from
              <span>
                <a href="https://www.flaticon.com/" target="_blank">www.flaticon.com</a>
                <md-tooltip md-direction="top" style="font-size: larger">www.<b>flaticon</b>.com</md-tooltip>
                </span> is licensed by <span>
                <a href="http://creativecommons.org/licenses/by/3.0/" target="_blank">CC 3.0 BY</a>
                <md-tooltip md-direction="top" style="font-size: larger">Creative Commons BY 3.0</md-tooltip>
              </span>
            </div>

            <div v-if="!isAuthenticated">
              <hr>
              <div class="grid-x grid-padding-x grid-padding-y">
                <div class="cell small-12 medium-12 large-6">
                  <router-link to="/Registrierung">
                    <button class="button success register expanded">
                      <strong>SoSE</strong> für Ihre Einrichtung <strong>ausprobieren</strong>&nbsp;?
                    </button>
                  </router-link>
                </div>
                <div class="cell small-12 medium-12 large-6 text-center">
                  <router-link to="/Registrierung/Test">
                    <button class="button success register expanded"><strong>Test-Lizenz</strong> anfragen</button>
                  </router-link>
                </div>
              </div>
              <hr>
              <div class="grid-x grid-padding-x grid-padding-y">
                <div class="cell">
                  <p class="md-title" style="color: cornflowerblue;">Sie interessieren sich für die Kosten?</p>
                  <br>
                  <h5>
                    Mit unserem <strong>flexiblen Preismodell</strong> ist
                    SoSE für jede Einrichtung bezahlbar!
                    <br>
                    Bestimmen Sie einfach selbst Ihren Preis!
                  </h5>
                </div>
              </div>
              <div class="grid-x grid-padding-x grid-padding-y">
                <div class="cell small-12 medium-12 large-6 text-center">
                  <router-link to="/Preise">
                    <button class="button success register expanded"><strong>Preisrechner</strong> starten</button>
                  </router-link>
                </div>
                <div class="cell small-12 medium-12 large-6 text-center">
                  <router-link to="/Kuendigung">
                    <button class="button success register expanded"><strong>Verträge</strong> hier kündigen</button>
                  </router-link>
                </div>
              </div>
            </div>

          </md-card-header>
          <md-card-expand v-if="isAuthenticated">
            <md-card-actions md-alignment="left">
              <md-card-expand-trigger>
                <md-button class="md-icon-button">
                  <md-icon>keyboard_arrow_down</md-icon>
                </md-button>
              </md-card-expand-trigger>
              <div>
                <label style="padding-left: 8px;">Mehr Informationen</label>
              </div>
            </md-card-actions>

            <md-card-expand-content>
              <md-card-content>
                <h5>Gründer und Geschäftsführer von
                  <span>
                      <a href="http://www.ai42.de/" target="_blank">www.ai42.de</a>
                      <md-tooltip md-direction="top"
                                  style="font-size: larger">erfahre mehr auf www.<b>ai42</b>.de</md-tooltip>
                    </span>
                </h5>
                <h5><b>Wolfgang Füssel</b> und <b>Torsten Hoffmann</b></h5>
              </md-card-content>
            </md-card-expand-content>
          </md-card-expand>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'SoSEInforamtionView',

  computed: {
    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    version() {
      return this.$store.getters.version;
    },
  }
}
</script>

<style lang="scss" scoped>

a:hover {
  text-decoration: none;
}

.md-card-media {
  width: 99%;
}

h5 {
  font-weight: 300;
}

h5 b {
  font-weight: 400;
  color: cadetblue;
}

.centered {
  position: absolute;
  top: 85%;
  left: 50%;
  right: -40%;
  transform: translate(-35%, -50%);
}

h4 {
  font-weight: 300;
}

p {
  margin: 0;
}

span {
  color: cornflowerblue;
}

.register {
  text-transform: none;
  font-weight: 300;
  font-size: 1.2rem;
  background-color: transparent;
}

.md-theme-dark .register {
  color: whitesmoke;
}

.button.success.register {
  padding: 10px 9px 9px;
  border: 1px solid lightgrey;
}

strong {
  font-weight: 500;
  color: orange;
}

</style>
