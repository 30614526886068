<template>
  <div v-if="serverList" class="grid-x grid-padding-x grid-padding-y">
    <div class="cell">
      <md-card>
        <md-card-expand>
          <md-card-actions md-alignment="left">
            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
              </md-button>
            </md-card-expand-trigger>
            <div class="md-title">Netzwerk-Status</div>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content>
              <table class="hover" style="width: 100%">
                <thead>
                <tr>
                  <td></td>
                  <td v-if="isSage">Server Url</td>
                  <td v-else>Server Nr</td>
                  <td>Status</td>
                  <td>letzter Zugriff</td>
                  <td>letzter Ausfall</td>
                  <td>nächster Versuch</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(server, index) of serverList" :key="server.url" :style="isActiveServer(index)">
                  <td><span v-if="index === serverIndex">&#9654;</span></td>
                  <td v-if="isSage">{{ server.url }}</td>
                  <td v-else>Server {{ index + 1 }}</td>
                  <td v-html="booleanText(server.available)"></td>
                  <td>{{ server.lastAccessTime | moment("dddd, DD.MM.YYYY, HH:mm:ss") }}</td>
                  <td>{{ server.failureTime | moment("dddd, DD.MM.YYYY, HH:mm:ss") }}</td>
                  <td>{{ server.nextConnectTime | moment("dddd, DD.MM.YYYY, HH:mm:ss") }}</td>
                </tr>
                </tbody>
              </table>
            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
      </md-card>
    </div>
    <div v-if="isSage" class="cell">
      <md-card>
        <md-card-expand>
          <md-card-actions md-alignment="left">
            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
              </md-button>
            </md-card-expand-trigger>
            <div class="md-title">Applikations-Konfiguration</div>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content>
              <table class="hover">
                <thead>
                </thead>
                <tbody>
                <tr v-for="(value, key) of environment" :key="key">
                  <td><p>{{ key }}</p></td>
                  <td><p><span>{{ value }}</span></p></td>
                </tr>
                </tbody>
              </table>
            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemView',

  methods: {
    booleanText(value) {
      return value ? '<span style="color: darkseagreen;">verfügbar</span>' : '<span style="color: red;">nicht verfügbar</span>';
    },

    isActiveServer(index) {
      return index === this.serverIndex ? 'color: darkseagreen' : '';
    }
  },

  computed: {
    serverList() {
      return this.$store.getters.serverList;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    environment() {
      return process.env;
    },

    serverIndex() {
      return this.$store.getters.serverListIndex;
    }
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

p span {
  color: cornflowerblue;
}

table {
  width: 100% !important;
}

.md-title {
  padding-left: 8px;
}
</style>